import { PROJECT_NAME, LOCALE, APP_NAME } from '../env'
import CONFIG from '../configurator'
import { getCookie } from './cookies'
import { ProjectList } from './constants'
import translate from '../locale/translate'

const langs = CONFIG[PROJECT_NAME].langs[LOCALE]

function clickHandler(e, link, isExternal) {
  if (isExternal) {
    window.location.href = link
    return
  }
  e.preventDefault()
  window._push(link)
  window.header.closeMenu()
}

export default (footerOptions, headerOptions) => {
  generateFooter(footerOptions)
  generateHeader(headerOptions)
}

const generateFooter = ({ onLanguageChange }) => {
  window.footer.rebuildLanguage({
    data: langs,
    selectedId: getCookie(`${APP_NAME}_language`) || 'en',
    onSelect: onLanguageChange,
  })
}

const generateHeader = ({ formatMessage, products, kitsData }) => {
  const navList = []
  const kits = [kitsData.kind, ...kitsData.extensions]

  // CONTENTPREVIEW
  if (PROJECT_NAME === ProjectList.CONTENTPREVIEW) {
    const mainItem = {
      name: 'Products',
    }
    const localeMap = {
      highSchool: 'high-school',
      middleSchool: 'middle-school',
      university: 'university',
    }
    const { highSchool, middleSchool, university } = products.modules[0]

    const contentList = {
      middleSchool,
      highSchool,
      university,
    }
    mainItem.subMenu = Object.keys(contentList).map((item) => {
      return {
        name: translate(localeMap[item]),
        extend: [
          {
            links: contentList[item].map((link) => {
              const URL = link.customLink || `/content-preview/${localeMap[item]}/${link.template}/${link.kitKind}`
              return {
                name: link.title,
                url: URL,
                onClick: (e) => clickHandler(e, URL, Boolean(link.customLink)),
              }
            }),
          },
        ],
      }
    })

    navList.push(mainItem)
  }

  //ENGINEERING_KIT
  if (PROJECT_NAME === ProjectList.ENGINEERING_KIT) {
    const lessonItem = {
      name: 'Getting Started',
      extend: [
        {
          links: buildSimpleMenu((products.modules && products.modules[0]) || [], kits, 'lesson'),
        },
      ],
    }

    const productItem = {
      name: 'Activities',
      extend: [
        {
          links: buildSimpleMenu((products.modules && products.modules[0]) || [], kits, 'project'),
        },
      ],
    }
    navList.push(lessonItem, productItem)
  }

  //STARTER_KIT || STUDENT_KIT || ProjectList.IOT_SK
  if (
    PROJECT_NAME === ProjectList.STARTER_KIT ||
    PROJECT_NAME === ProjectList.STUDENT_KIT ||
    PROJECT_NAME === ProjectList.IOT_SK
  ) {
    const [gettingStarted, ...links] = buildSimpleMenu((products.modules && products.modules[0]) || [], kits, 'lesson')

    const lessonItem = {
      name: 'Lessons',
      extend: [
        {
          links: links.map((item, index) => {
            return {
              ...item,
              name: `${index + 1}. ${item.name}`,
            }
          }),
        },
      ],
    }

    navList.push(gettingStarted, lessonItem)
  }

  // CTC-GO
  if (PROJECT_NAME === ProjectList.CTC_GO) {
    const mainItem = {
      name: formatMessage({
        id: 'headerModules',
      }),
    }
    const localeMap = {
      modules: 'Core',
      extensions: 'Motion',
    }

    mainItem.subMenu = Object.keys(products).map((item) => {
      return {
        name: localeMap[item],
        extend: products[item].map((module, index) => {
          const moduleURL = `/${module.kitKind.toLowerCase()}/module/${module.slug}`
          return {
            module: {
              name: `PART ${++index}`,
              url: moduleURL,
              onClick: (e) => clickHandler(e, moduleURL),
            },
            blocks: [
              {
                name: 'Lessons',
                links: buildSimpleMenu(module, kits, 'lesson'),
              },
              {
                name: 'Activities',
                links: buildSimpleMenu(module, kits, 'project'),
              },
            ],
          }
        }),
      }
    })
    navList.push(mainItem)
  }

  // SCIENCE JOURNAL
  if (PROJECT_NAME === ProjectList.SJ) {
    const { modules, extensions } = products

    const modulesItem = {
      name: 'Modules',
    }

    const gettingStarted = {
      name: 'Getting Started',
      extend: [
        {
          links: buildSimpleMenu((modules && modules[0]) || [], kits, 'lesson'),
        },
      ],
    }

    modulesItem.subMenu = extensions.map((item) => {
      return {
        name: item.title,
        extend: [
          {
            blocks: [
              {
                name: 'Lessons',
                links: buildSimpleMenu(item, kits, 'lesson'),
              },
              {
                name: 'Activities',
                links: buildSimpleMenu(item, kits, 'project'),
              },
            ],
          },
        ],
      }
    })
    navList.push(gettingStarted, modulesItem)
  }

  if (CONFIG[PROJECT_NAME].glossary) {
    navList.push({
      name: formatMessage({
        id: 'headerGlossary',
      }),
      url: '/glossary',
      onClick: (e) => clickHandler(e, '/glossary'),
    })
  }
  if (CONFIG[PROJECT_NAME].resources) {
    navList.push({
      name: formatMessage({
        id: 'headerGlossary',
      }),
      url: '/resources',
      onClick: (e) => clickHandler(e, '/resources'),
    })
  }

  window.header.renderMenu(navList)
}

const checkAccess = ({ kitKind }, list = []) => {
  if (kitKind.includes('+')) {
    return kitKind.split('+').some((i) => list.includes(i))
  }
  return list.includes(kitKind)
}

function buildSimpleMenu(module, kits, type = 'lesson') {
  const list = module[`${type}s`].map((item) => {
    if (!checkAccess(item, kits)) {
      return null
    }
    const kind = module.kitKind.toLowerCase()
    const url = `/${kind}/module/${module.slug}/${type}/${item.slug}`
    return {
      url,
      name: item.title,
      onClick: (e) => clickHandler(e, url),
    }
  })

  return list.filter(Boolean)
}
