import React from 'react'
import PropTypes from 'prop-types'
import { IntlProvider } from 'react-intl'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { RemoveScroll } from 'react-remove-scroll'
import { APP_NAME, PROJECT_NAME } from '../../../env'
import CONFIG from '../../../configurator'
import { rootActions } from '../../../store/root'

import WelcomePopover from './WelcomePopover/WelcomePopover'
import { getCookie } from '../../../utils/cookies'
import HF from './HF'

const Layout = (props) => {
  const { _site = { title: '', favicon: { url: '' } }, messages, modules, extensions } = props.data
  return (
    <IntlProvider locale={props.language} messages={messages}>
      <HF
        onLanguageChange={props.onLanguageChange}
        userRole={props.userRole}
        products={{ modules, extensions }}
        kitsData={props.kitsData}
      />
      <div className="app">
        <Helmet>
          <title>{_site.title || APP_NAME}</title>
          {_site.favicon && <link rel="shortcut icon" href={_site.favicon.url} type="image/png" />}
          <meta
            name="description"
            content="Arduino Education is focused on creating the next generation of STEAM programs that progress students through middle school, high school, and university and help them thrive."
          />
        </Helmet>
        {!getCookie('visited') && CONFIG[PROJECT_NAME].showPopover && <WelcomePopover />}
        <div className="container wrap">{props.children}</div>
      </div>
      {props.lightbox && window.innerWidth > 980 && (
        <RemoveScroll enabled={true} removeScrollBar={false}>
          <div id="lightbox" className="lightbox">
            <div className="step-block-close-fullscreen" role="button" onClick={() => props.triggerLightbox(null)}>
              <i className="icon-fullscreen_close" />
            </div>
            {props.lightbox}
          </div>
        </RemoveScroll>
      )}
    </IntlProvider>
  )
}

Layout.propTypes = {
  language: PropTypes.string,
  isFirstVisit: PropTypes.bool,
  userRole: PropTypes.string,
  kitsData: PropTypes.object,
  data: PropTypes.object,
  locale: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.func]),
  onLanguageChange: PropTypes.func,
  triggerLightbox: PropTypes.func,
  lightbox: PropTypes.object,
}

const mapDispatchToProps = {
  onLanguageChange: rootActions.onLanguageChange,
  triggerLightbox: rootActions.triggerLightbox,
}

const mapStateToProps = (state) => {
  return {
    lightbox: state.root.lightbox,
    isFirstVisit: state.root.profile.data.isFirstVisit,
    userRole: state.root.kits.data.role,
    kitsData: state.root.kits.data,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Layout))
