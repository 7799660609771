import { PROJECT_NAME } from '../env'

const ProjectList = {
  CTC_GO: 'ctc-go',
  STARTER_KIT: 'starter-kit',
  STUDENT_KIT: 'student-kit',
  ENGINEERING_KIT: 'engineering-kit',
  CONTENTPREVIEW: 'content-preview',
  IOT_SK: 'explore-iot',
  SJ: 'science-journal',
}

const AppAccessible = {
  [ProjectList.CTC_GO]: 'CTCGO',
  [ProjectList.CONTENTPREVIEW]: 'CONTENTPREVIEW',
  [ProjectList.SJ]: 'SJ',
  [ProjectList.IOT_SK]: 'IOTSK',
  [ProjectList.STARTER_KIT]: 'AESK',
  [ProjectList.STUDENT_KIT]: 'STUDENTKIT',
  [ProjectList.ENGINEERING_KIT]: 'AEKR2',
}

const UserRoles = {
  STUDENT: 'student',
  TEACHER: 'teacher',
  ADMIN: 'admin',
  COPA: 'COPA',
}

function hasExtendedAccess(role, required) {
  return (
    role === UserRoles.TEACHER || role === UserRoles.ADMIN || (PROJECT_NAME === ProjectList.STUDENT_KIT && !required)
  )
}

export { ProjectList, UserRoles, hasExtendedAccess, AppAccessible }

export const DATO_IMAGE = `
srcSet
webpSrcSet
sizes
src

width
height
aspectRatio

alt
title

bgColor

base64

`
