import { store } from '../views/App'

const localization = {
  'learn-more': {
    en: 'Learn more about:',
    es: 'Cónoce más:',
    it: 'Impariamo di più su:',
  },
  'middle-school': {
    en: 'Middle school',
  },
  'high-school': {
    en: 'High school',
  },
  university: {
    en: 'University',
  },
}

export default function (key) {
  const selected = store.getState().root.profile.language || 'en'
  if (!localization[key]) {
    return ''
  }
  return localization[key][selected] || ''
}
