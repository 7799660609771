import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './WelcomePopover.scss'
import { setCookie } from '../../../../utils/cookies'

export default function WelcomePopover() {
  const [isVisible, setVisible] = useState(true)
  if (!isVisible) return null

  return (
    <div
      className="welcome-popover__overlay"
      onClick={() => {
        setVisible(false)
        setCookie('visited', true)
      }}>
      <div className="welcome-popover">
        <div className="welcome-popover__title">Access Arduino Create here!</div>
        <div className="welcome-popover__text">
          By activating your Explore IoT Kit, you have unlocked more features on Arduino Create. The activities in this
          kit will show you how to use the platform. To access it easily, click on this icon.
        </div>
        <div className="welcome-popover__button">
          <span>GOT IT</span>
        </div>
      </div>
    </div>
  )
}

WelcomePopover.propTypes = {
  onPopoverClick: PropTypes.func,
}
