/**
 * Monkey-patch the fetch function to send the root header
 */

export default function authenticatedFetch(input, init) {
  let patchedInit = init
  if (!init || typeof init !== 'object') {
    patchedInit = {}
  }

  return window.auth
    .getAccessToken()
    .then((authToken) => {
      const patchedHeaders = Object.assign(patchedInit.headers || {}, {
        Authorization: `Bearer ${authToken}`,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      })

      patchedInit = Object.assign(patchedInit, {
        headers: patchedHeaders,
      })

      return fetch(input, patchedInit)
    })
    .catch((e) => {
      if (e.error === 'login_required') {
        window.auth.login({ href: window.location.href })
      }
    })
}
