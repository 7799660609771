import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import generateFooterAndHeader from '../../../utils/generateFooterAndHeader'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { hasExtendedAccess } from '../../../utils/constants'
import { PROJECT_NAME } from '../../../env'
import CONFIG from '../../../configurator'

function HF({ intl: { formatMessage }, onLanguageChange, userRole, products, kitsData }) {
  const { push } = useHistory()
  window._push = push
  useEffect(() => {
    generateFooterAndHeader({ onLanguageChange }, { formatMessage, push, products, kitsData })
  }, [])

  return (
    <>
      {CONFIG[PROJECT_NAME].dashboard && hasExtendedAccess(userRole) && (
        <style
          dangerouslySetInnerHTML={{
            __html: '#ino-header-new #dashboard-link {display: inline-block;}',
          }}
        />
      )}
    </>
  )
}

HF.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  onLanguageChange: PropTypes.func,
  userRole: PropTypes.string,
  products: PropTypes.object,
  kitsData: PropTypes.object,
}

export default injectIntl(React.memo(HF))
