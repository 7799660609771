/* eslint-disable */

/**
 * This file exposes the variables passed from Webpack and read from the current env file (/env/*.sh)
 */

import { ProjectList } from './utils/constants';

export const APP_NAME = process.env.APP_NAME;
export const BASE_URL = process.env.BASE_URL;
export const APP_URL = process.env.APP_URL;
export const AUTH_URL = process.env.AUTH_URL;
export const CDN_URL = process.env.CDN_URL;
export const API_URL = process.env.API_URL;
export const NODE_ENV = process.env.NODE_ENV;

export const DATOCMS_API = process.env.DATOCMS_API;
export const DATOCMS_API_TOKEN = process.env.DATOCMS_API_TOKEN;
export const DATOCMS_GQL_API = process.env.DATOCMS_GQL_API;

export const REDIRECT_URL = process.env.REDIRECT_URL;
export const EDUCATION_DASHBOARD = process.env.EDUCATION_DASHBOARD;

export const SUPPORT_API = process.env.SUPPORT_API;

export const HF_URL = process.env.HF_URL;
export const PROFILE_URL = process.env.PROFILE_URL;
export const DIGITAL_STORE_URL = process.env.DIGITAL_STORE_URL;
export const LOCALE = process.env.LOCALE;

export const PROJECT_NAME = process.env.PROJECT || ProjectList.CTC_GO;

export const NODE_ENV_DEV = NODE_ENV === 'development';
