import types from './types'
import { APP_NAME } from '../../env'
import { setCookie } from '../../utils/cookies'

const onLanguageChange = (language) => {
  setCookie(`${APP_NAME}_language`, language.id)
  return {
    type: types.CHANGE_LANGUAGE,
    language: language.id,
  }
}

const login = (redirect) => ({
  type: types.LOGIN,
  redirect,
})

const loginRequested = () => ({
  type: types.LOGIN_REQUESTED,
})

const loginSucceeded = (token) => ({
  type: types.LOGIN_SUCCEEDED,
  token,
})

const loginFailed = (error) => ({
  type: types.LOGIN_FAILED,
  error,
})

const getProfile = () => ({
  type: types.GET_PROFILE,
})

const getProfileRequested = () => ({
  type: types.GET_PROFILE_REQUESTED,
})

const getProfileSucceeded = (profile) => ({
  type: types.GET_PROFILE_SUCCEEDED,
  profile,
})

const getProfileFailed = (error) => ({
  type: types.GET_PROFILE_FAILED,
  error,
})

const getKits = () => ({
  type: types.GET_KITS,
})

const getKitsRequested = () => ({
  type: types.GET_KITS_REQUESTED,
})

const getKitsSucceeded = (currentKits) => ({
  type: types.GET_KITS_SUCCEEDED,
  currentKits,
})

const getKitsFailed = (error) => ({
  type: types.GET_KITS_FAILED,
  error,
})

const triggerLightbox = (data) => ({
  type: types.TRIGGER_LIGHTBOX,
  data,
})

export default {
  login,
  loginRequested,
  loginSucceeded,
  loginFailed,
  getProfile,
  getProfileRequested,
  getProfileSucceeded,
  getProfileFailed,
  getKits,
  getKitsRequested,
  getKitsSucceeded,
  getKitsFailed,
  onLanguageChange,
  triggerLightbox,
}
