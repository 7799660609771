const LOGIN = 'app/root/LOGIN';
const LOGIN_REQUESTED = 'app/root/LOGIN_REQUESTED';
const LOGIN_SUCCEEDED = 'app/root/LOGIN_SUCCEEDED';
const LOGIN_FAILED = 'app/root/LOGIN_FAILED';

const GET_PROFILE = 'app/root/GET_PROFILE';
const GET_PROFILE_REQUESTED = 'app/root/GET_PROFILE_REQUESTED';
const GET_PROFILE_SUCCEEDED = 'app/root/GET_PROFILE_SUCCEEDED';
const GET_PROFILE_FAILED = 'app/root/GET_PROFILE_FAILED';

const GET_KITS = 'app/root/GET_KITS';
const GET_KITS_REQUESTED = 'app/root/GET_KITS_REQUESTED';
const GET_KITS_SUCCEEDED = 'app/root/GET_KITS_SUCCEEDED';
const GET_KITS_FAILED = 'app/root/GET_KITS_SUCCEEDED';

const CHANGE_LANGUAGE = 'app/root/CHANGE_LANGUAGE';

const TRIGGER_LIGHTBOX = 'app/root/TRIGGER_LIGHTBOX';

export default {
  LOGIN,
  LOGIN_REQUESTED,
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,

  GET_PROFILE,
  GET_PROFILE_REQUESTED,
  GET_PROFILE_SUCCEEDED,
  GET_PROFILE_FAILED,

  GET_KITS,
  GET_KITS_REQUESTED,
  GET_KITS_SUCCEEDED,
  GET_KITS_FAILED,

  CHANGE_LANGUAGE,
  TRIGGER_LIGHTBOX,
};
