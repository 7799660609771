const English = { id: 'en', value: 'English' }
const Spanish = { id: 'es', value: 'Spanish' }
const German = { id: 'de', value: 'German' }
const Portuguese = { id: 'pt', value: 'Portuguese' }
const Italian = { id: 'it', value: 'Italian' }
const French = { id: 'fr', value: 'French' }

const CONFIG = {
  'ctc-go': {
    faq_button_link: '/faq',
    faq: true,
    glossary: true,
    resources: false,
    single: false,
    langs: {
      production: [English, Spanish, German, Portuguese, Italian, French],
      development: [English, Spanish, German, Portuguese, Italian, French],
      extensions: new Set(['en', 'es']),
    },
    db_v: '1',
    contact_us: true,
    dashboard: true,
    showTipsIcon: true,
    levelOfDifficulty: true,
    cardNumbering: true,
  },
  'content-preview': {
    isPublicContent: true,
    isContentPreview: true,
    faq_button_link: 'https://www.arduino.cc/education/contact-us',
    faq: false,
    glossary: true,
    resources: false,
    single: true,
    langs: {
      production: [English],
      development: [English],
    },
    db_v: '1',
    contact_us: false,
    dashboard: false,
    showTipsIcon: true,
    levelOfDifficulty: true,
    cardNumbering: true,
  },
  'explore-iot': {
    faq_button_link: 'https://www.arduino.cc/education/contact-us',
    faq: false,
    glossary: false,
    resources: false,
    single: true,
    langs: {
      production: [English],
      development: [English, Italian, Spanish],
    },
    db_v: '1',
    contact_us: false,
    dashboard: false,
    showPopover: true,
    showTipsIcon: true,
    levelOfDifficulty: true,
    cardNumbering: true,
  },
  'starter-kit': {
    faq_button_link: '/contact-us',
    faq: false,
    glossary: false,
    resources: true,
    single: true,
    langs: {
      production: [English, Spanish, Italian],
      development: [English, Spanish, Italian, German],
    },
    db_v: '1.2',
    contact_us: true,
    dashboard: true,
    showTipsIcon: true,
    levelOfDifficulty: true,
    cardNumbering: true,
  },
  'student-kit': {
    faq_button_link: '/contact-us',
    faq: false,
    glossary: false,
    resources: true,
    single: true,
    langs: {
      production: [English, Spanish, Italian],
      development: [English, Spanish, Italian, German],
    },
    db_v: '1.2',
    contact_us: true,
    dashboard: false,
    showTipsIcon: true,
    levelOfDifficulty: true,
    cardNumbering: true,
  },
  'engineering-kit': {
    faq_button_link: '/contact-us',
    faq: false,
    glossary: false,
    resources: false,
    single: true,
    langs: {
      production: [English],
      development: [English, Spanish],
    },
    db_v: '1.1',
    contact_us: true,
    dashboard: true,
    showTipsIcon: false,
    levelOfDifficulty: false,
    cardNumbering: false,
  },
  'science-journal': {
    isPublicContent: true,
    faq_button_link: 'https://www.arduino.cc/education/contact-us',
    faq: false,
    glossary: true,
    resources: false,
    single: false,
    langs: {
      production: [English],
      development: [English],
    },
    db_v: '1',
    contact_us: false,
    dashboard: false,
    showTipsIcon: false,
    levelOfDifficulty: false,
    cardNumbering: false,
  },
}

export const additionalFragment = {
  '1': {
    blocks: '',
  },
  '1.1': {
    blocks: `... on ResourceLinkRecord {
      id
      resource {
        id
        title
      }
    }
    ... on NoteRecord {
      id
      description(markdown: true)
    }`,
  },
  '1.2': {
    blocks: `... on ResourceLinkRecord {
      id
      resource {
        id
        title
      }
    }
    ... on NoteRecord {
      id
      description(markdown: true)
    }
    ... on WhateverNoteRecord {
      id
      title
      note(markdown: true)
      downloadLink
      video {
        url
      }
    }`,
  },
}

export default CONFIG
