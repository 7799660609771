import React, { Suspense, useEffect } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter, Redirect, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import { rootActions } from '../../store/root'
import { LayoutProvider } from '../ui-common/Layout/layout-context'
import LayoutContainer from '../ui-common/Layout/LayoutContainer'
import Loader from '../ui-common/Loader/Loader'
import PrivateRoute from '../../utils/PrivateRoute'
import CONFIG from '../../configurator'
import { BASE_URL, PROJECT_NAME } from '../../env'

import '../../styles/App.scss'

import 'highlight.js/styles/arduino-light.css'

import hljs from 'highlight.js/lib/core'
import bash from 'highlight.js/lib/languages/bash'
import cpp from 'highlight.js/lib/languages/c-like'
import javascript from 'highlight.js/lib/languages/javascript'
import python from 'highlight.js/lib/languages/python'
import matlab from 'highlight.js/lib/languages/matlab'
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'

hljs.registerLanguage('bash', bash)
hljs.registerLanguage('c', cpp)
hljs.registerLanguage('js', javascript)
hljs.registerLanguage('python', python)
hljs.registerLanguage('matlab', matlab)

window.MathJax = {
  tex: {
    inlineMath: [
      ['$', '$'],
      ['\\(', '\\)'],
    ],
  },
  svg: {
    fontCache: 'global',
  },
}

require('mathjax/es5/tex-chtml')

const Home = React.lazy(() => import('../Home/HomeContainer'))

const Glossary = React.lazy(() => import('../Glossary/GlossaryContainer'))
const Resources = React.lazy(() => import('../Resources'))
const FAQ = React.lazy(() => import('../FAQ/FAQContainer'))
const FAQPage = React.lazy(() => import('../FAQPage/FAQPageContainer'))
const ModulesList = React.lazy(() => import('../ModulesList'))
const Lesson = React.lazy(() => import('../Lesson/LessonContainer'))
const Project = React.lazy(() => import('../Project/ProjectContainer'))
const ContactUs = React.lazy(() => import('../ContactUs'))
const CPLessonProvider = React.lazy(() => import('../ContentPreview/lesson/CPLessonProvider'))
const CPProjectProvider = React.lazy(() => import('../ContentPreview/project/CPProjectProvider'))
const AppComponent = ({ onLogin, loggedIn }) => {
  // check and make authenticate
  useEffect(() => {
    if (!loggedIn) {
      onLogin()
    }
  }, [])

  return (
    <BrowserRouter basename={BASE_URL}>
      <Suspense fallback={<Loader />}>
        <LayoutProvider>
          <LayoutContainer>
            <Switch>
              <PrivateRoute exact path="/" component={Home} />
              <PrivateRoute exact path="/:kind/module/:module/lesson/:lessonSlug" component={Lesson} />
              <PrivateRoute exact path="/:kind/module/:module/project/:projectSlug" component={Project} />

              {CONFIG[PROJECT_NAME].resources && <PrivateRoute exact path="/resources" component={Resources} />}
              {CONFIG[PROJECT_NAME].faq && [
                <PrivateRoute key="/faq" exact path="/faq" component={FAQ} />,
                <PrivateRoute key="/faq/:type" exact path="/faq/:type" component={FAQPage} />,
              ]}

              {CONFIG[PROJECT_NAME].glossary && (
                <PrivateRoute key="/glossary" exact path="/glossary" component={Glossary} />
              )}

              {CONFIG[PROJECT_NAME].contact_us && <PrivateRoute exact path="/contact-us" component={ContactUs} />}

              {!CONFIG[PROJECT_NAME].single && (
                <PrivateRoute exact path="/:kind/module/:module" component={ModulesList} />
              )}

              {CONFIG[PROJECT_NAME].isContentPreview && [
                <PrivateRoute
                  exact
                  key="lesson"
                  path="/content-preview/:type/lesson/:kind"
                  component={CPLessonProvider}
                />,
                <PrivateRoute
                  exact
                  key="project"
                  path="/content-preview/:type/project/:kind"
                  component={CPProjectProvider}
                />,
              ]}

              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </LayoutContainer>
        </LayoutProvider>
      </Suspense>
    </BrowserRouter>
  )
}

AppComponent.propTypes = {
  loggedIn: PropTypes.bool,
  onLogin: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.root.login.loggedIn,
    kitsData: state.root.kits.data,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (redirect) => dispatch(rootActions.login(redirect)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppComponent)
