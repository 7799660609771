import React, { useEffect } from 'react'
import Loader from '../views/ui-common/Loader/Loader'
import { useQuery } from '@apollo/client'
import { store } from '../views/App'
import { PROJECT_NAME } from '../env'
import CONFIG from '../configurator'

const defaultLang = 'en'

const detectRequiredFields = (object, path) => {
  return path.reduce((accumulator, currentValue) => {
    if (accumulator && accumulator[currentValue]) {
      accumulator = accumulator[currentValue]
    } else {
      accumulator = undefined
    }
    return accumulator
  }, object)
}

const QueryWrapper = (query, Component, props, variables = {}, settings = {}) => {
  const locale = store.getState().root.profile.language
  const { required /*...options */ } = settings

  const extensionsLocales = CONFIG[PROJECT_NAME].langs?.extensions || new Set([])
  const extensionsLang = extensionsLocales.has(locale) ? locale : defaultLang
  const { data, refetch, loading, fetchMore } = useQuery(query, {
    variables: { locale, extensionsLang, ...variables },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (!locale) {
      return
    }

    //  refetch data when document doesn't translated
    if (data && required?.length) {
      const isRequiredDetected = detectRequiredFields(data, required)
      if (!isRequiredDetected && locale !== defaultLang) {
        refetch({ locale: defaultLang, extensionsLang })
      }
    }
  })

  useEffect(() => {
    if (!locale) {
      return
    }
    refetch({ locale, extensionsLang })
  }, [locale])

  if (loading || !data) {
    return <Loader />
  }

  return <Component {...props} data={data} fetchMore={fetchMore} refetch={refetch} locale={locale} />
}

export default QueryWrapper
