import { call, put, takeEvery, fork } from 'redux-saga/effects'
import jwtDecode from 'jwt-decode'
import actions from './actions'
import AuthenticationTypes from './types'
import { REDIRECT_URL, PROJECT_NAME } from '../../env'
import { getKits } from '../../apis/kits'
import { AppAccessible, ProjectList, UserRoles } from '../../utils/constants'
import CONFIG from '../../configurator'

const { isPublicContent } = CONFIG[PROJECT_NAME]

/**
 * Checks if the user is authenticated. If not, tries
 * to authenticate via the header-footer Auth0 method.
 */
const login = function* login() {
  yield put(actions.loginRequested())
  // value for content-preview project
  if (isPublicContent) {
    yield put(
      actions.getKitsSucceeded({
        kind: AppAccessible[PROJECT_NAME],
        language: 'en',
        role: 'admin',
      }),
    )
  }
  try {
    // get token
    const token = yield call(window.auth.getAccessToken)
    // get profile
    yield put(actions.loginSucceeded(token))
  } catch (error) {
    if (!isPublicContent) {
      if (error.error === 'login_required') {
        return (window.location.href = REDIRECT_URL)
      } else {
        yield put(actions.loginFailed(error))
      }
    }
  }
}

// get Profile
const getProfile = function* getProfile({ token }) {
  yield put(actions.getProfileRequested())
  try {
    if (PROJECT_NAME === ProjectList.STUDENT_KIT) {
      const decoded = jwtDecode(token)
      if (decoded['http://arduino.cc/roles'].includes('STUDENTKIT:student')) {
        // don't call user profile when user are log in as COPA user
        return yield put(actions.getProfileSucceeded({ role: UserRoles.COPA }))
      }
    }
    const profile = yield call(window.auth.getProfile)
    return yield put(actions.getProfileSucceeded(profile))
  } catch (error) {
    yield put(actions.getProfileFailed(error))
  }
}

const watchLogin = function* watchLogin() {
  yield takeEvery(AuthenticationTypes.LOGIN, login)
}

const watchLoginSuccess = function* watchLoginSuccess() {
  yield takeEvery(AuthenticationTypes.LOGIN_SUCCEEDED, loginSuccess)
}

const loginSuccess = function* loginSuccess(token) {
  yield fork(() => getProfile(token))
}

const watchGetProfile = function* watchGetProfile() {
  yield takeEvery(AuthenticationTypes.GET_PROFILE, loginSuccess)
}

const getKitsSaga = function* getKitsSaga() {
  yield put(actions.getKitsRequested())
  try {
    const kits = yield call(getKits)

    if (Array.isArray(kits) && kits.length) {
      const index = kits.findIndex((item) => item.kind === AppAccessible[PROJECT_NAME])

      if (index === -1) {
        window.location.href = REDIRECT_URL
      }

      yield put(actions.getKitsSucceeded(kits[index]))
    } else {
      window.location.href = REDIRECT_URL
    }
  } catch (error) {
    yield put(actions.getKitsFailed(error))
  }
}

const watchGetKits = function* watchGetKits() {
  yield takeEvery(AuthenticationTypes.LOGIN_SUCCEEDED, getKitsFork)
}

const getKitsFork = function* getKitsFork() {
  if (!isPublicContent) {
    yield fork(getKitsSaga)
  }
}

export default [watchLogin, watchLoginSuccess, watchGetProfile, watchGetKits]
